<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-0">
      <v-spacer />
      <router-link to="ach/create" v-slot="{ href, navigate }">
        <v-btn :href="href" @click="navigate" color="primary"> <v-icon left>mdi-upload</v-icon> Create New </v-btn>
      </router-link>
      </v-card-title>
      <v-data-table
        disable-pagination
        :hide-default-footer="true"
        :must-sort="true"
        :no-data-text="''"
        :headers="headers"
        :items="achRecords"
        :loading="loading"
        :disable-sort="true"
        @click:row="item => selectAch(item)"
      >
        <template v-slot:item.accountTypeId="{ item }">
          {{ accountTypes.find(at => at.id === item.accountTypeId).name }}
        </template>
        
        <template v-slot:item.orgUnits="{ item }">
          <div v-for="(orgUnit, i) in item.orgUnits" :key="i">
            {{orgUnit.name}}
          </div>
        </template>
        
        <template v-slot:item.parties="{ item }">
          <div v-for="(party, i) in item.parties" :key="i">
            {{party.name}}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Auth0Mixin from "../Auth0Mixin";
import achService from "../../services/achService";
import AccountTypeList from "../../constants/accountTypeList";
import orgUnitService from "../../services/admin/orgUnitService";

export default {
  name: "AchList",
  mixins: [Auth0Mixin],
  data() {
    return {
      headers: [
        { text: "Bank Name", value: "bankName" },
        { text: "Routing Number", value: "routingNumber" },
        { text: "Account Number", value: "accountNumber" },
        { text: "Account Type", value: "accountTypeId" },
        { text: "Profiles", value: "orgUnits" },
        { text: "Party", value: "parties" },
      ],
      achRecords: [],
      loading: true,
      accountTypes: AccountTypeList,
    };
  },
  methods: {
    async init() {
      let achRecords = (await achService.getAll()).items;
      
      let orgUnitIds = achRecords.map(a => a.partiesByOrgUnitId.map(pair => pair.key)).flat();
      let uniqueOrgUnitIds = [...new Set(orgUnitIds)];
      let uniqueOrgUnits = (await orgUnitService.getList(true, null, null, uniqueOrgUnitIds.join())).items;
      
      for (const ach of achRecords) {
        let orgUnits = [];
        
        ach.partiesByOrgUnitId.forEach(pair => {
          let orgUnit = uniqueOrgUnits.find(x => x.id == pair.key);
          let associatedOrgUnits = Array(pair.value.length).fill(orgUnit);
          orgUnits.push(...associatedOrgUnits)
        });
        
        let parties = ach.partiesByOrgUnitId.map(pair => pair.value).flat();

        this.achRecords.push({
          id: ach.id,
          bankName: ach.bankName,
          routingNumber: ach.routingNumber,
          accountNumber: ach.accountNumber,
          accountTypeId: ach.accountTypeId,
          orgUnits: orgUnits,
          parties: parties
        });
      }

      this.loading = false;
    },

    selectAch(ach) {
      this.$router.push({ name: "Ach", params: { id: ach.id } });
    },
  },
};
</script>
