<template>
  <v-container fluid>
    <v-flex class="ma-4">
      <v-card class="elevation-1 card mt-4 pa-4 card-form">
        <LoadingProgress :loading="loading" />
        <v-form ref="form">
          <v-card-text>
            <v-row>
              <h3 class="headline mb-4">{{ isCreate ? "Upload" : "Edit" }} {{ documentTypeName }}</h3>
              <v-spacer />
              <div v-show="!isCreate">
                <ActionDropdown :actions="actions" :performAction="performAction" />
              </div>
            </v-row>
            <div class="entityForm">
            <EntityForm :entity="document" :inputFields="documentFields">
              <v-data-table
                v-if="isShared"
                v-model="document.selectedParties"
                :headers="headers"
                :items="enhancedParties"
                item-key="id"
                show-select
              >
              </v-data-table>
            </EntityForm>
            </div>
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="save()" :disabled="loading" large color="primary" depressed>Save</v-btn>
          <v-btn @click="cancel()" :disabled="loading" large depressed>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import Auth0Mixin from "../Auth0Mixin";
import DocumentServices from "@/services/admin/legalDocumentService";
import partyService from "@/services/partyService";
import partyStatus from "@/constants/partyStatus";
import downloadService from "@/services/downloadService";
import ActionDropdown from "@/components/ActionDropdown";
import LegalDocumentTypes from "@/constants/legalDocumentTypeList";
//import LegalDocumentTypeList from "@/constants/documentTypeTemplateList";
import LoadingProgress from "@/components/LoadingProgress";
import EntityForm from "@/components/EntityForm";
// import ValidationRules from "@/validationRules";
import { API_URL } from "../../env";
import {
  LegalDocumentPrivacySetting,
  LegalDocumentPrivacySettingList,
} from "../../constants/legalDocumentPrivacySettings.js";
import { mapGetters } from "vuex";

const DELETE_ACTION = "Delete";
const DOWNLOAD_ACTION = "Download";

const FIELDS = [
  {
    label: "upload file",
    attribute: "file",
    type: "uploadFile",
    icon: "mdi-file-image",
    accept: "*.pdf,*.doc,*.png",
    hint: "Click to select the document",
  },
  { label: "Privacy Setting", type: "radio", attribute: "privacySettingId", items: LegalDocumentPrivacySettingList },
  { label: "Active", attribute: "isActive", type: "checkbox" },
];

export default {
  name: "LegalDocument",
  mixins: [Auth0Mixin],
  props: {
    legalDocumentTypeId: Number,
    passedId: String,
  },
  components: { LoadingProgress, EntityForm, ActionDropdown },
  data() {
    return {
      document: {
        id: 0,
        file: null,
        privacySettingId: 1,
        selectedParties: [],
        isActive: true,
      },
      originalActiveStatus: true,
      actions: [DELETE_ACTION],
      documentFields: FIELDS,
      parties: [],
      alreadySelectedParties: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Number", value: "number", sortable: false },
      ],
      isCreate: false,
      loading: false,
    };
  },
  methods: {
    async init() {
      this.loading = true;
      this.isCreate = this.passedId === "create";
      if (!this.isCreate) {
        this.actions.push(DOWNLOAD_ACTION);
      }

      this.documentFields.find(e => e.attribute === "file").disabled = !this.isCreate;

      if (this.isCreate) {
        this.document = {
          id: 0,
          privacySettingId: 1,
          file: null,
          legalDocumentTypeId: this.legalDocumentTypeId,
          isActive: true,
        };
      } else {
        this.document = await DocumentServices.get(Number(this.passedId));
        this.alreadySelectedParties = this.document.selectedParties;
        this.originalActiveStatus = this.document.isActive;
      }

      this.parties = (
        await partyService.search(true, "", 5, partyStatus.CONNECTED, {
          sortDesc: [false],
          page: 1,
          itemsPerPage: 100,
          sortBy: "name",
        })
      ).items;
      this.loading = false;
    },
    async save() {
      if (this.isCreate && !this.document.file) {
        return this.$root.$snackbar.error("Please select a file");
      }

      try {
        this.loading = true;
        let existingActiveDocument = (
          await DocumentServices.searchDocuments({
            legalDocumentTypeId: this.document.legalDocumentTypeId,
            isActive: true,
          })
        ).items.filter(item => item.id !== this.document.id);

        // we are replacing existing active if there is already active and create one or update exiting to active from inactive
        let replaceExistingActive =
          existingActiveDocument.length && (this.isCreate || (!this.originalActiveStatus && this.document.isActive));
        let saveDocument = true;
        if (replaceExistingActive) {
          var documentTypeName = LegalDocumentTypes.find(x => x.id === this.document.legalDocumentTypeId).name;
          saveDocument = await this.$root.$confirmDialog.open(
            "Replace existing document",
            `This will replace existing ${documentTypeName} active document. By clicking continue, the existing active document will be inactive, and this will become the new active ${documentTypeName} document`,
            "Continue"
          );
        }
        if (saveDocument) {
          var details = {
            detailsDto: {
              legalDocumentTypeId: this.document.legalDocumentTypeId,
              privacySettingId: this.document.privacySettingId,
              isActive: this.document.isActive,
              selectedParties: this.document.selectedParties,
            },
          };

          if (this.isCreate) await DocumentServices.uploadDocument(this.document.file, details);
          else await DocumentServices.updateDocument(this.document.id, details.detailsDto);

          this.$root.$snackbar.message("Successfully uploaded the document");
          this.loading = false;
          this.$router.push({ name: "LegalDocuments", params: { legalDocumentTypeId: this.legalDocumentTypeId } });
        } else {
          this.loading = false;
        }
      } catch (e) {
        if (e.response.status === 500) this.$root.$snackbar.error(e.response.data.error.message);
        else if (e.toString().includes("status code 501"))
          this.$root.$snackbar.warn("The file was uploaded but extract data failed.");
        else this.$root.$snackbar.error("Cannot upload this document, please contact support");
        this.loading = false;
        this.$router.push({ name: "LegalDocuments", params: { legalDocumentTypeId: this.legalDocumentTypeId } });
      }
    },
    async performAction(action) {
      if (action === DELETE_ACTION) {
        let confirm = await this.$root.$confirmDialog.open("Confirm Delete", "Are you sure?", "Delete");
        if (!confirm) return;

        await DocumentServices.deleteDocument(this.document.id);
        this.$root.$snackbar.message(`Document Deleted`);
        this.$router.push(`/admin/settings/legalDocuments`);
      }
      if (action === DOWNLOAD_ACTION) {
        const url = `${API_URL}/legaldocuments/${this.document.id}/download`;
        downloadService.download(url, this.selectedCompanyId, this.auth0Token);
      }
    },
    cancel() {
      this.$router.push({ name: "LegalDocuments", params: { legalDocumentTypeId: this.legalDocumentTypeId } });
    },
  },
  computed: {
    ...mapGetters("auth0Store", ["selectedCompanyId", "auth0Token"]),
    isShared: function() {
      return this.document.privacySettingId === LegalDocumentPrivacySetting.SHARED_WITH_PARTNERS;
    },
    enhancedParties: function() {
      return this.parties.map(x => ({
        isSelectable: !this.alreadySelectedParties.some(p => p.id === x.id),
        ...x,
      }));
    },
    documentTypeName() {
      return LegalDocumentTypes.find(x => x.id == this.legalDocumentTypeId)?.name;
    },
  },
};
</script>

<style scoped>
.entityForm {
  margin-top: 20px;
}
</style>